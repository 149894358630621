@font-face {
  font-family: 'Baloo Bhai 2';
  src: local('Baloo Bhai 2 Bold'), url('./assets/fonts/BalooBhai2-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Baloo Bhai 2';
  src: local('Baloo Bhai 2 Extra Bold'), url('./assets/fonts/BalooBhai2-ExtraBold.ttf') format('truetype');
}
@font-face {
  font-family: 'Baloo Bhai 2';
  src: local('Baloo Bhai 2 Medium'), url('./assets/fonts/BalooBhai2-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'Baloo Bhai 2';
  src: local('Baloo Bhai 2 Semi Bold'), url('./assets/fonts/BalooBhai2-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Baloo Bhai 2';
  src: local('Baloo Bhai 2 Regular'), url('./assets/fonts/BalooBhai2-Regular.ttf') format('truetype');
}
body {
  margin: 0;
  color:#E4D8C8;
  scroll-behavior: smooth;
  /* font-family: -apple-system, BlinkMacSystemFont, 'baloo bhai 2','Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */
font-family:  -apple-system, BlinkMacSystemFont,'Baloo Bhai 2', 'Roboto';
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

